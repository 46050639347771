import React, { useEffect, useState } from "react";
import DarkTheme from "layouts/Dark";
import Navbar from "components/Navbar/navbar";
import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";
import { GetBlogPostData } from "components/ApiServices/GetBlogPostsdata";
import BlogGrid from "components/Blog-grid/blog-grid";
import SEO from "components/seo/seo";
import LoadingScreen from "components/Loading-Screen";

const Tag = ({ pageContext }) => {
  const getBlogPosts = GetBlogPostData();
  const tagN = pageContext?.node?.name;

  const [blogData, setBlogData] = useState(null);

  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  const [theme, setTheme] = useState("Dark");

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });

    var storage = localStorage.getItem("theme");

    if (storage && storage != "") {
      let bodyEl = document.querySelector("body");
      bodyEl.classList.add("themeL");
      setTheme(storage);
    }
  }, [navbarRef]);

  const themeModeChange = (themeMode) => {
    let bodyEl = document.querySelector("body");
    if (themeMode == "Dark") {
      setTheme("themeL");
      bodyEl.classList.add("themeL");
      localStorage.setItem("theme", "themeL");
    } else {
      setTheme("Dark");
      bodyEl.classList.remove("themeL");
      localStorage.removeItem("theme");
    }
  };

  const filterByTechnology = (data, tagName) => {
    return data.filter((blogs) => {
      return blogs.node.tags.edges.some((tag) => {
        return tag.node.name.toLowerCase().includes(tagName.toLowerCase());
      });
    });
  };

  useEffect(() => {
    let blogs = filterByTechnology(getBlogPosts, tagN);

    if (blogs && blogs.length > 0) {
      setBlogData(blogs);
    }
  }, [getBlogPosts, tagN]);

  return (
    <DarkTheme>
      <LoadingScreen/>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar
        nr={navbarRef}
        lr={logoRef}
        theme={theme}
        themeModeChange={themeModeChange}
      />
      <PageHeader
        title={pageContext?.node?.name.toUpperCase()}
        paragraph="All the most current news and events of our creative team."
      />
      <BlogGrid blogs={blogData} />
      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  const liveUrl = typeof window !== "undefined" ? window.location.href : "";

  return (
    <>
      <SEO canonical={liveUrl} />
    </>
  );
};

export default Tag;
